@import url('https://fonts.googleapis.com/css2?family=Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import './theme.scss';
* {
	@font-face {
		font-family: 'Cisco Sans';
		src: url('assets/font/CiscoSansTTLight.ttf') format('truetype');
		font-weight: 400;
		font-style: normal;
	  }
	  
	  @font-face {
		font-family: 'Cisco Sans';
		src: url('assets/font/CiscoSansRegular.otf') format('opentype');
		font-weight: 500;
		font-style: normal;
	  }
	  
	  @font-face {
		font-family: 'Cisco Sans';
		src: url('assets/font/CiscoSansThin.otf') format('opentype');
		font-weight: 300;
		font-style: normal;
	  }
	  
	  @font-face {
		font-family: 'Cisco Sans';
		src: url('assets/font/CiscoSansTTBold.ttf') format('truetype');
		font-weight: 600;
		font-style: normal;
	  }
	
	
	font-family: 'Spartan', 'Cisco Sans', sans-serif;
	z-index: 10;
}

body, html {
	max-width: 100vw;
	overflow-x: hidden;
}

body {
	background-color: $bg-primary;
	color: $lightgrey;
}

hr {
	background-color: $primary;
	height: 1px !important;
	opacity: 1;
}

a.link {
	color: $secondary;
	transition: 0.1s ease-out;
	text-decoration: none;
	&:hover {
		color: adjust-color($color: $primary, $lightness: -15%);
		text-decoration: underline;
	}
}