@import '../../theme.scss';

#helyszin {
	h2.title {
		color: $primary;
	}
	.text {
		color: $primary;
	}
	text-align: center;
	padding: 80px 0;
	img.map {
		width: 100%;
		border-radius: 10px;
		max-width: 1000px;
	}
}