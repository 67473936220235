@import '../../theme.scss';

.button {
	background-color: $primary;
	color: $secondary;
	border: 0;
	height: 45px;
	border-radius: 22.5px;
	padding: 5px 30px;
	font-size: 0.9rem;
	line-height: 38px;
	box-shadow: 0 0px 7px 0px rgb(0 0 0 / 20%);
	&.glow {
		box-shadow: 0px 2px 13px adjust-color($color: $primary, $alpha: 0.2);
	}
	transition: all 0.1s ease-out;
	text-decoration: none;
	display: inline-block;
	
	svg {
		fill: $secondary;
		height: 15px;
		margin-right: 10px;
		transform: translateY(-1px);
	}

	&:hover {
		transform: translateY(-2px);
		box-shadow: 0 3px 7px 0px rgb(0 0 0 / 20%);
		/* background-color: adjust-color($color: $iok-lightblue, $lightness: 10%); */
		background-color: $iok-lightblue;
		color: $light;
		svg {
			fill: $light;
		}
	}

	&:active {
		transform: translateY(-1px);
		box-shadow: 0 1px 3px 0px rgb(0 0 0 / 20%);
	}

	&.bold {
		font-weight: 700;
	}

	&.secondary {
		background-color: $light;
		color: $grey;
		&:hover {
			background-color: adjust-color($color: $light, $lightness: -1%);
			color: adjust-color($color: $primary, $lightness: -10%)
		}
	}
}